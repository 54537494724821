import React from "react";
import './disclaimers.css'

const Policy = () =>{
    return(
        <div className="policy">
            <h1>Domits Privacy Policy</h1>
            <input type="text" placeholder="Search within privacy policy..." />

            <h3>Privacy Policy</h3>
            <p>Last updated: December 28, 2023</p>
            <p>This Privacy Policy describes Our policies and procedures on the collection,
                use and disclosure of Your information when You use the Service and tells
                You about Your privacy rights and how the law protects You.</p>
            <p>We use Your Personal data to provide and improve the Service.
                By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

            <h3>Interpretation and Definitions</h3>
            <p>Interpretation</p>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
                The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <br />
            <h3>Definitions</h3>
            <br />
            <p>For the purposes of this Privacy Policy:</p>
            <p>Account means a unique account created for You to access our Service or parts of our Service.
                Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares,
                equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
            <p>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Domits.com, Kinderhuissingel 6-K, Haarlem 2013 AS.</p>
            <p>Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
                Country refers to: Netherlands.</p>
            <p>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
            <p>Personal Data is any information that relates to an identified or identifiable individual.</p>
            <p>Service refers to the Website.</p>
            <p>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
            <p>Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
            <p>Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
            <p>Website refers to Domits, accessible from Domits.com</p>
            <p>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
        </div>
    );
}

export default Policy;